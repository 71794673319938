import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetCompaniesList = (strategyId) => {
  let urlString = `/company/getInvestingCompanies`;

  if (strategyId && strategyId.length > 1) {
    urlString += `?strategyId=${strategyId}`;
  }

  const data = useQuery(["getCompaniesList", strategyId], () =>
    axiosClient.get(`${urlString}`)
  );

  return data;
};
export default useGetCompaniesList;
