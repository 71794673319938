import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select2";
import { useTranslation } from "react-i18next";
import { Div, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import years from "../../../assets/icons/years.svg";
import percentage from "../../../assets/icons/percent.svg";
import { Switch } from "../../../components/Switch";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";

export const BondProgram = ({ 
  sourceForm, 
  originalSourceForm,
  setSourceForm, 
  handleSelect 
}) => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;

  const helperProps = {
    helperColor: theme.colors.gray300
  }

  const interestOptions = [
    { value: 2, label: "Variable" },
    { value: 1, label: "Fixed" },
  ];

  const amortizationOptions = [
    { value: 1, label: "Bullet" },
    { value: 2, label: "Partially amortizing" },
    { value: 3, label: "Fully amortizing" },
  ];

  const returnDebtPayment = (value) => {
    switch (value) {
      case 1:
        return "Bullet";
      case 2:
        return "Partially amortizing";       
      case 3:
        return "Fully amortizing";
      default:
        return "";
    }
  };

  const returnInterest = (value) => {
    switch (value) {
      case 1:
        return "Fixed";
      case 2:
        return "Variable";       
      default:
        return "";
    }
  };

  const interest = returnInterest(originalSourceForm?.interest);
  const debtPayment = returnDebtPayment(originalSourceForm?.debtPayment);

  return (
    <>
      <Text 
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        color={theme.colors.blue}
        mb="22px"
      >
        {t("bondProgram")}
      </Text>
      <Row gutter={[32, 32]} style={{ width: '100%' }}>
        <Col md={8}>
          <Input 
            id="legalTenor"
            label={t("seniorDebtForm.legalTenor")}
            value={FormatNumber(sourceForm?.legalTenor, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale)}
            suffix={<img alt="years" src={years} />}
            helper={`${t("sourceValue")}: ${FormatNumber(originalSourceForm?.legalTenor, locale)}`}
            {...helperProps}
          />
        </Col>
        <Col md={8}>
          <Select 
            id="interest"
            label={t("seniorDebtForm.interest")}
            options={interestOptions}
            value={sourceForm?.interest}
            onChange={(e) => handleSelect(e, "interest")}
            helper={`${t("sourceValue")}: ${interest}`}
            {...helperProps}
            width={"100%"}
          />
        </Col>
        <Col md={8}>
          <Input 
            id="makeWholeComission"
            label={t("seniorDebtForm.commitmentFee")}
            suffix={<img alt="percentage" src={percentage} />}
            value={FormatNumber(sourceForm?.makeWholeComission, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
            helper={`${t("sourceValue")}: ${FormatNumber(originalSourceForm?.makeWholeComission, locale)}`}
            {...helperProps}
          />
        </Col>
        <Col md={8}>
          <Select 
            id="debtPayment"
            label={t("seniorDebtForm.amortizationType")}
            options={amortizationOptions}
            value={sourceForm?.debtPayment}
            onChange={(e) => handleSelect(e, "debtPayment")}
            helper={`${t("sourceValue")}: ${debtPayment}`}
            {...helperProps}
            width={"100%"}
          />
        </Col>
        <Col md={8}>
          <Input 
            id="financingOpeningFee"
            label={t("juniorDebtForm.upFrontFee")}
            value={FormatNumber(sourceForm?.financingOpeningFee, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
            suffix={<img alt="percentage" src={percentage} />}
            helper={`${t("sourceValue")}: ${FormatNumber(originalSourceForm?.financingOpeningFee, locale)}`}
            {...helperProps}
          />
        </Col>
        <Col md={8}>
          <Input 
            id="financingSpread"
            label={t("juniorDebtForm.margin")}
            value={FormatNumber(sourceForm?.financingSpread, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
            suffix={<img alt="percentage" src={percentage} />}
            helper={`${t("sourceValue")}: ${FormatNumber(originalSourceForm?.financingSpread, locale)}`}
            {...helperProps}
          />
        </Col>
        <Col md={24}>
          <Div align="center" gap="15px">
            <Switch
              checked={sourceForm?.cashSweepActivation}
              onChange={(e) => setSourceForm({...sourceForm, cashSweepActivation: e})}
            />
            <Text color={theme.colors.gray500}>
              {t("cashSweep")}
            </Text>
          </Div>
        </Col>
      </Row>
    </>
  )
};


BondProgram.propTypes = {
  sourceForm: PropTypes.object,
  originalSourceForm: PropTypes.object,
  setSourceForm: PropTypes.func,
  handleSelect: PropTypes.func,
};