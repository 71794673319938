import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { Input } from "../../../components/Input";
import { useTranslation } from "react-i18next";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import percentage from "../../../assets/icons/percent.svg";
import { FormatNumber, HandleChangeNumber } from "../../../utilities/numberFunctions/handleChange";

export const Intermediation = ({ sourceForm, originalSourceForm, setSourceForm }) => {
  const { t, i18n } = useTranslation("strategies");
  const locale = i18n.language;

  const helperProps = {
    helperColor: theme.colors.gray300
  }

  const totalOpeningFee = parseFloat((Number(sourceForm.intermediationOpeningFee) + Number(sourceForm?.financingOpeningFee)).toFixed(2));
  const totalSpread = parseFloat((Number(sourceForm.intermediationSpread) + Number(sourceForm?.financingSpread)).toFixed(2));

  return (
    <>
      <Text 
        size={theme.fonts.size.h6}
        weight={theme.fonts.weight.medium}
        color={theme.colors.blue}
        mb="22px"
      >
        {t("intermediation")}
      </Text>
      <Row gutter={[70, 32]} style={{ width: '100%' }}>
        <Col md={12}>
          <Input 
            id="intermediationOpeningFee"
            label={t("intermediationForm.upFrontFee")}
            value={FormatNumber(sourceForm.intermediationOpeningFee, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
            suffix={<img alt="percentage" src={percentage} />}
            helper={`${t("sourceValue")}: ${FormatNumber(originalSourceForm?.intermediationOpeningFee, locale)}`}
            {...helperProps}
          />
        </Col>
        <Col md={12}>
          <Input 
            id="intermediationSpread"
            label={t("intermediationForm.marginFee")}
            value={FormatNumber(sourceForm.intermediationSpread, locale)}
            onChange={(e) => HandleChangeNumber(e, sourceForm, setSourceForm, locale, "percentage")}
            suffix={<img alt="percentage" src={percentage} />}
            helper={`${t("sourceValue")}: ${FormatNumber(originalSourceForm?.intermediationSpread, locale)}`}
            {...helperProps}
          />
        </Col>
        <Col md={12}>
          <Input 
            id="totalOpeningFee"
            label={t("intermediationForm2.upFrontFeeTot")}
            value={FormatNumber(totalOpeningFee, locale)}
            suffix={<img alt="percentage" src={percentage} />}
            disabled={true}
          />
        </Col>
        <Col md={12}>
          <Input 
            id="totalSpread"
            label={t("intermediationForm2.spreadTot")}
            value={FormatNumber(totalSpread, locale)}
            suffix={<img alt="percentage" src={percentage} />}
            disabled={true}
          />
        </Col>
      </Row>
    </>
  )
};

Intermediation.propTypes = {
    sourceForm: PropTypes.object,
    originalSourceForm: PropTypes.object,
    setSourceForm: PropTypes.func,
  };