import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Div, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { theme } from "../../../../styles/theme";
import search from "../../../../assets/icons/Search.svg";
import filterIcon from "../../../../assets/icons/Filtrar.svg";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button";
import eolicImg from "../../../../assets/images/eolicImage.png";
import eolicDisabledImg from "../../../../assets/images/eolicDisabled.png";
import solarImg from "../../../../assets/images/solar.png";
import solarDisabledImg from "../../../../assets/images/solarDisabled.png";
import useGetProjects from "../../hooks/useGetProjects";
import { Link } from "../../../../components/Link";
import {
  getUserInfo,
  fetchImageUrl,
  getToken,
} from "../../../../utilities/helpers";
import { FilterDrawer } from "./filterDrawer";
import { FinancingProjects } from "./financingProjects";
import { SaleProjects } from "./saleProjects";
import useSetActivityLog from "../../../InvestorProjectDetail/hooks/useSetActivityLog";
import { Col, Row } from "antd";
import { CloseIcon } from "../../../../assets/icons";
import ModalPreferences from "./ModalPreferences";
import useGetPresentPreference from "../../hooks/useGetPresentPreference";

export const InvestorProjectList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("projectsTabsView");
  const { companyId } = getUserInfo();
  const { projectType } = useSelector((state) => state?.common);
  const setLog = useSetActivityLog();
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [totalCreated, setTotalCreated] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchAction, setFetchAction] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [imageUrl, setImageUrl] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [showModalPreferences, setShowModalPreferences] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  const getPresentPreference = useGetPresentPreference();
  const { data: dataPreference, isSuccess: isSuccessPreference } =
    getPresentPreference;
  useEffect(() => {
    if (isSuccessPreference) {
      setShowModalPreferences(dataPreference?.data?.data.presentPreference);
    }
  }, [dataPreference, isSuccessPreference]);

  useEffect(() => {
    const loadImage = async () => {
      if (companyId) {
        try {
          setIsLoadingImg(true);
          const url = `documentation/download-by-company?category=9&companyId=${companyId}`;
          const image = await fetchImageUrl(url, getToken());
          setImageUrl(image);
        } catch (error) {
          if (error?.response?.statusText !== "Not Found") {
            toast.error(t("errorImage"), error);
          }
        } finally {
          setIsLoadingImg(false);
        }
      }
    };

    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const [payload, setPayload] = useState({
    instance: [],
    limit: pageSize,
    query: "",
    offset: 0,
    projectType: [],
    selectedTab: "created",
  });

  const getProjects = useGetProjects(payload);
  const [projectsData, setProjectsData] = useState([]);
  const { data: dataProjects, isLoading, isSuccess } = getProjects;

  useEffect(() => {
    if (isSuccess && dataProjects?.data) {
      setProjectsData(dataProjects?.data?.projects);
      setTotalCreated(dataProjects?.data?.totalCreated);
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjects, isSuccess]);

  const initialFilterValues = {
    type: [],
    capacity: [],
    countries: [],
    phase: [],
    strategies: [],
  };
  const [filtersOptions, setFilterOptions] = useState(initialFilterValues);
  const [confirmedFilterValues, setConfirmedFilterValues] =
    useState(initialFilterValues);

  useEffect(() => {
    setPayload({
      ...payload,
      limit: pageSize,
      query: searchValue,
      projectType: confirmedFilterValues?.type,
      projectTotalMW: confirmedFilterValues?.capacity,
      projectStatus: confirmedFilterValues?.phase,
      countries: confirmedFilterValues?.countries,
      strategies: confirmedFilterValues?.strategies,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, fetchAction, confirmedFilterValues, searchValue]);

  useEffect(() => {
    const instance = projectType === "debt" ? "financing" : "sale";
    setPayload({
      ...payload,
      instance: [instance],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType]);

  const returnImg = (type, disabled) => {
    switch (type) {
      case "solar":
        if (!disabled) {
          return solarImg;
        } else {
          return solarDisabledImg;
        }

      case "eolic":
        if (!disabled) {
          return eolicImg;
        } else {
          return eolicDisabledImg;
        }
      default:
        break;
    }
  };

  const handleFilterChange = (group, id) => (e) => {
    const { checked } = e.target;
    const label = id;
    setFilterOptions((prevValues) => {
      const newValues = [...prevValues[group]];

      if (checked) {
        newValues.push(label);
      } else {
        const index = newValues.indexOf(label);
        if (index > -1) {
          newValues.splice(index, 1);
        }
      }

      return {
        ...prevValues,
        [group]: newValues,
      };
    });
  };

  const handleReFetch = () => {
    setPageSize(5);
    setFetchAction(!fetchAction);
  };

  const handleSaveFilters = () => {
    setConfirmedFilterValues(filtersOptions);
    setShowFilter(false);
    handleReFetch();
  };

  const cleanFilters = () => {
    const newFilterValues = {
      type: [],
      capacity: [],
      countries: [],
      phase: [],
      strategies: [],
    };
    setFilterOptions(newFilterValues);
    setConfirmedFilterValues(newFilterValues);
    setShowFilter(false);
    handleReFetch();
  };

  const handleSearch = () => {
    handleReFetch();
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
      setIsSearch(!isSearch);
    }
  };

  const handleDetail = (projectId) => {
    const payload = {
      action: "visit-opportunity",
      projectId: projectId,
    };

    navigate(`/projects/investor-project-detail?projectId=${projectId}`);
    setLog.reset();
    setLog.mutate(payload);
  };

  const handleCloseFilter = () => {
    setFilterOptions(confirmedFilterValues);
    setShowFilter(false);
  };

  const handleLoadProjects = () => {
    setPageSize(pageSize + 5);
  };

  const handleResetFilterAndSearch = () => {
    setSearchValue("");
    handleReFetch();
    setIsSearch(false);
    setFilterOptions(initialFilterValues);
    setConfirmedFilterValues(initialFilterValues);
  };

  if (initialLoading) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  return (
    <>
      <Div height="min-content" justify="space-between" align="center">
        <FilterDrawer
          open={showFilter}
          onClose={handleCloseFilter}
          filtersOptions={filtersOptions}
          handleFilterChange={handleFilterChange}
          handleSaveFilters={handleSaveFilters}
          cleanFilters={cleanFilters}
        />
        <ModalPreferences
          showModal={showModalPreferences}
          handleCloseModal={() => setShowModalPreferences(false)}
        />
        <Div direction="column" gap="16px">
          {isLoadingImg ? (
            <Div width="264px" height="70px" justify="center" align="center">
              <ReactLoading color={theme.colors.green} />
            </Div>
          ) : (
            imageUrl && (
              <img
                alt="icon"
                src={imageUrl}
                width="264px"
                height={"70px"}
                style={{ objectFit: "contain", objectPosition: "left" }}
              />
            )
          )}
          <Text size={theme.fonts.size.h6} color={theme.colors.gray300}>
            {t("projectsSubtitle")}
          </Text>
        </Div>
        <Div gap="24px">
          <Input
            sizeLabel={theme.fonts.size.xs}
            suffix={
              <img
                alt="search"
                src={search}
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              />
            }
            width={"323px"}
            placeholder={`${t("common:search")}...`}
            style={{ borderRadius: "23px", marginTop: "-10px" }}
            mt={"-8px"}
            value={searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <Button
            width={"110px"}
            variant={"outlined"}
            onClick={() => setShowFilter(true)}
          >
            {t("filter")}
            <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
          </Button>
        </Div>
      </Div>
      <Div direction="column" m="42px 0 52px 0" gap="26px">
        <Text
          color={theme.colors.blue}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
        >
          {t("allProjects")}
        </Text>
        <Text color={theme.colors.gray300}>{t("projectsMessage")}</Text>
      </Div>
      {projectsData.length === 0 && (
        <Col width="100%" justify="center" align="center" m="24px 0">
          <Text color={theme.colors.gray500} size={theme.fonts.size.h6}>
            {t("projectsTabsView:nosearch")}
          </Text>
          <Row justify="center" align="center">
            <Text
              color={theme.colors.green}
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.medium}
              style={{ cursor: "pointer" }}
              onClick={handleResetFilterAndSearch}
            >
              {t("projectsTabsView:cleanSearch")}
            </Text>
            <CloseIcon fill={theme.colors.green} />
          </Row>
        </Col>
      )}
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        <>
          {projectType === "debt" ? (
            <FinancingProjects
              projectsData={projectsData}
              returnImg={returnImg}
              handleDetail={handleDetail}
            />
          ) : (
            <SaleProjects
              projectsData={projectsData}
              returnImg={returnImg}
              handleDetail={handleDetail}
            />
          )}
          {payload.limit < totalCreated && (
            <Div
              width="100%"
              m="40px 0 0 0"
              justify="center"
              onClick={handleLoadProjects}
            >
              <Link>{t("loadProjects")}</Link>
            </Div>
          )}
        </>
      )}
    </>
  );
};
