import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetCounterProjects = () => {
  const data = useQuery(
    ["getCounterProjects"],
    () => axiosClient.get(`project/counterProjectsInstance`),
  );

  return data;
};

export default useGetCounterProjects;