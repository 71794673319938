import React from "react";
import PropTypes from "prop-types";
import { Row, Text, Col } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Input } from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/Select2";
import { debtProfileOptions } from "../../../../utilities/generalOptions";
import {
  FormatNumber,
} from "../../../../utilities/numberFunctions/handleChange";
import { formatCurrency } from "../../../../utilities/numberFunctions/handleChangeCurrency";

export const TransactionSummaryEsiBond = ({
  strategyData,
  setStrategyData,
  tabActiveKey,
}) => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const locale = i18n.language;

  const handleChangeStrategy = (event) => {
    const { value, id } = event.target;
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1].transactionSummary.esiBond[id] =
        value;
      return updatedItems;
    });
  };

  const handleChangeNumberStrategy = (event, locale, source) => {
    const { value, id } = event.target;
    const newForm = [...strategyData];

    const formatValue = value.replace(/[^\d.,]/g, "");

    let formattedValue;
    if (locale === "es") {
      formattedValue = formatValue.replace(".", ",");
    } else {
      formattedValue = formatValue.replace(",", ".");
    }

    if (source === "percentage") {
      const numericValue = parseFloat(formattedValue.replace(",", "."));
      if (numericValue > 100) {
        formattedValue = "100";
      }
    }

    newForm[Number(tabActiveKey) - 1].transactionSummary.esiBond[id] =
      formattedValue;

    setStrategyData(newForm);
  };

  const handleChangeCurrency = (event) => {
    const { id, value } = event.target;

    const newFormInfo = [...strategyData];

    newFormInfo[Number(tabActiveKey) - 1].transactionSummary.esiBond[id] = value
    setStrategyData(newFormInfo);
  };

  const handleSelectStrategy = (value, id) => {
    setStrategyData((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1].transactionSummary.esiBond[id] =
        value;
      return updatedItems;
    });
  };

  return (
    <>
      <Row gap="28px" m="33.5px 0 0 0">
        <Input
          label={t("details")}
          id="details"
          sizeLabel={theme.fonts.size.default}
          width="100%"
          height="138px"
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .details
          }
          onChange={handleChangeStrategy}
        />
      </Row>
      <Row gap="28px" m="25px 0 0 0">
        <Input
          label={t("seniorSecured")}
          id="seniorSecuredProjectBond"
          sizeLabel={theme.fonts.size.default}
          width="100%"
          suffix="%"
          value={FormatNumber(
            strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .seniorSecuredProjectBond,
            locale
          )}
          onChange={(e) => handleChangeNumberStrategy(e, locale, "percentage")}
        />
        <Input
          label={t("issuer")}
          id="issuer"
          sizeLabel={theme.fonts.size.default}
          width="100%"
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .issuer
          }
          onChange={handleChangeStrategy}
        />
        <Input
          label={t("amount")}
          id="amount"
          sizeLabel={theme.fonts.size.default}
          width="100%"
          suffix="€"
          value={formatCurrency(strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond.amount, locale)}
          onChange={handleChangeCurrency}
        />
      </Row>
      <Row gap="28px" m="25px 0 0 0">
        <Input
          label={t("coupon")}
          id="coupon"
          sizeLabel={theme.fonts.size.default}
          width="100%"
          value={
            FormatNumber(strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
            .coupon, locale)
          }
          onChange={(e) => handleChangeNumberStrategy(e, locale)}
        />
        <Select
          colorPlaceholder={theme.colors.gray200}
          label={t("debtProfile")}
          sizeLabel={theme.fonts.size.default}
          width="100%"
          arrowColor={theme.colors.gray200}
          options={debtProfileOptions}
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .profile
          }
          onChange={(value) => handleSelectStrategy(value, "profile")}
        />
        <Input
          label={t("maturity")}
          id="maturity"
          sizeLabel={theme.fonts.size.default}
          min={0}
          width="100%"
          suffix="Años"
          value={
            FormatNumber(
              strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .maturity, locale
            )
          }
          onChange={(e) => handleChangeNumberStrategy(e, locale)}
        />
      </Row>

      <Row gap="28px" m="25px 0 0 0">
        <Input
          label={t("security")}
          placeholder={t("securityDescription")}
          id="security"
          sizeLabel={theme.fonts.size.default}
          width="100%"
          height="138px"
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .security
          }
          onChange={handleChangeStrategy}
        />
      </Row>
      <Row gap="28px" m="25px 0 0 0">
        <Input
          label={t("listing")}
          id="listing"
          sizeLabel={theme.fonts.size.default}
          width="33%"
          value={
            strategyData[Number(tabActiveKey) - 1].transactionSummary.esiBond
              .listing
          }
          onChange={handleChangeStrategy}
        />
        <Col width="33%">
          <Text size={theme.fonts.size.default} color={theme.colors.gray200}>
            {t("loanLeverageCapex")}
          </Text>
          <Row align="center" gap="5px">
            <Input
              id="maxLoanLeverageOverCapexFrom"
              width="149px"
              value={
                FormatNumber(
                  strategyData[Number(tabActiveKey) - 1].transactionSummary
                  .esiBond.maxLoanLeverageOverCapexFrom, locale
                )
              }
              onChange={(e) => handleChangeNumberStrategy(e, locale)}
            />
            <Text size={theme.fonts.size.default}>-</Text>
            <Input
              id="maxLoanLeverageOverCapexTo"
              width="149px"
              value={
                FormatNumber(
                  strategyData[Number(tabActiveKey) - 1].transactionSummary
                  .esiBond.maxLoanLeverageOverCapexTo, locale
                )
              }
              onChange={(e) => handleChangeNumberStrategy(e, locale)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

TransactionSummaryEsiBond.propTypes = {
  strategyData: PropTypes.array,
  setStrategyData: PropTypes.func,
  tabActiveKey: PropTypes.string,
};
