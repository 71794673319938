import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRoute } from "../components/PublicRoute/PublicRoute";
import { App } from "./App";
import { Projects } from "./Projects";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import { ResetPasswordRequest } from "./ResetPasswordRequest";
import { Register } from "./Register";
import { LoginLink } from "./LoginLink";
import { LoadingLoginLink } from "./LoadingLoginLink";
import { MyAccount } from "./MyAccount";
import { NewProject } from "./NewProject";
import { NewPlant } from "./NewPlants";
import { ProjectDetail } from "./ProjectDetail";
import { Documents } from "./Documents";
import { EditProject } from "./EditProject";
import { UpdatePlant } from "./UpdatePlant";
import { PlantsDocumentsList } from "./PlantsDocumentsList";
import { PlantDocuments } from "./PlantDocuments";
import { InformationDeclaration } from "./InformationDeclaration";
import { DueDocuments } from "./DueDocuments";
import { CorporateDocuments } from "./CorporateDocuments";
import { InfoPlants } from "./InfoPlants";
import { InvestorProjectDetail } from "./InvestorProjectDetail";
import { TeaserDocumentation } from "./TeaserDocumentation";
import { Strategies } from "./Strategies";
import { Sources } from "./Sources";
import { SeeInvitations } from "./SeeInvitations";
import { Users } from "./Users";
import { Scraper } from "./Scraper";
import { DealIntel } from "./DealIntel";
import { UpdateSource } from "./UpdateSource";
import { InviteInvestor } from "./InviteInvestor";
import { ChangePassword } from "./ChangePassword";
import { VerifyAccount } from "./VerifyAccount";
import { InvestorActivity } from "./InvestorActivity";
import { FinancialModel } from "./FinancialModel";
import { UpdateSourceByProject } from "./UpdateSourceByProject";
import { ManagerQuestions } from "./ManagerQuestions";
import { ProjectActivity } from "./ProjectActivity";
import { PromoterQuestions } from "./PromoterQuestions";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="reset-password-request"
            element={
              <PublicRoute>
                <ResetPasswordRequest />
              </PublicRoute>
            }
          />
          <Route
            path="reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="email-sesion"
            element={
              <PublicRoute>
                <LoginLink />
              </PublicRoute>
            }
          />
          <Route
            path="loading-sesion"
            element={
              <PublicRoute>
                <LoadingLoginLink />
              </PublicRoute>
            }
          />
          <Route
            path="verify_account"
            element={
              <PublicRoute>
                <VerifyAccount />
              </PublicRoute>
            }
          />

          <Route path="projects">
            <Route index element={<Projects />} />

            <Route path="new-project" element={<NewProject />} />
            <Route path="new-plant" element={<NewPlant />} />
            <Route path="edit-plant" element={<UpdatePlant />} />
            <Route path="edit-project" element={<EditProject />} />
            <Route path="project-detail" element={<ProjectDetail />} />
            <Route
              path="information-declaration"
              element={<InformationDeclaration />}
            />
            <Route path="info-plants" element={<InfoPlants />} />
            <Route
              path="investor-project-detail"
              element={<InvestorProjectDetail />}
            />
            <Route
              path="teaser-documentation"
              element={<TeaserDocumentation />}
            />
            <Route path="see-invitations" element={<SeeInvitations />} />
            <Route path="deal-intel" element={<DealIntel />} />
            <Route path=":id/activity" element={<ProjectActivity />} />
            <Route path="manager-questions" element={<ManagerQuestions />} />
            <Route path="promoter-questions" element={<PromoterQuestions />} />
            <Route path="documents">
              <Route index element={<Documents />} />
              <Route path="plants">
                <Route index element={<PlantsDocumentsList />} />
                <Route path=":id" element={<PlantDocuments />} />
              </Route>
              <Route path="due-diligences" element={<DueDocuments />} />
              <Route
                path="corporate-documents"
                element={<CorporateDocuments />}
              />
            </Route>
            <Route path="financial-model/:id" element={<FinancialModel />} />
            <Route
              path=":projectId/source/:id"
              element={<UpdateSourceByProject />}
            />
          </Route>

          <Route path="my-account">
            <Route index element={<MyAccount />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
          <Route path="strategies">
            <Route index element={<Strategies />} />
            <Route path="source" element={<Sources />} />
            <Route path="source/:id" element={<UpdateSource />} />
          </Route>
          <Route path="scraper" element={<Scraper />} />
          <Route path="users">
            <Route index element={<Users />} />
            <Route path="invite-investor" element={<InviteInvestor />} />
            <Route
              path="investor-activity/:id"
              element={<InvestorActivity />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
