import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { Div, Row, Text } from "../../../../styles/Common";
import { Button } from "../../../../components/Button";
import { DownloadIcon } from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import { Card } from "../Questions/styles";
import { useTranslation } from "react-i18next";
import useSendTermsheetComments from "../../hooks/useSendTermsheetComments";
import useGetTermsheet from "../../hooks/useGetTermsheet";

const inputStyle = {
  width: "100%",
  padding: "8px",
  border: `1px solid ${theme.colors.gray300}`,
  borderRadius: "4px",
  fontSize: theme.fonts.size.default,
  textAlign: "left",
};

const Table = ({ t, termsheetForm, handleChange }) => {
  return (
    <table
      style={{ width: "50%", borderCollapse: "collapse", marginBottom: "24px" }}
    >
      <thead>
        <tr>
          <th
            style={{
              textAlign: "left",
              padding: "16px 8px",
              borderBottom: `2px solid ${theme.colors.gray100}`,
              fontSize: theme.fonts.size.default,
              width: "25%",
              height: "50px",
            }}
          ></th>
          <th
            style={{
              textAlign: "center",
              padding: "16px 8px",
              borderBottom: `2px solid ${theme.colors.gray100}`,
              fontWeight: theme.fonts.weight.medium,
              fontSize: theme.fonts.size.default,
              backgroundColor: theme.colors.light,
              height: "50px",
            }}
          >
            <span
              style={{
                color: theme.colors.green,
                fontSize: theme.fonts.size.default,
                fontWeight: theme.fonts.weight.medium,
              }}
            >
              Alter 5:
            </span>{" "}
            {t("table.indicativeTerms")}
          </th>
          <th
            style={{
              textAlign: "center",
              padding: "16px 8px",
              borderBottom: `2px solid ${theme.colors.gray100}`,
              fontSize: theme.fonts.size.default,
              fontWeight: theme.fonts.weight.medium,
              height: "50px",
            }}
          >
            <span
              style={{
                fontSize: theme.fonts.size.default,
                color: theme.colors.green,
              }}
            >
              Nombre inversor
            </span>
            <br /> {t("table.suggestedTerms")}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            style={{
              padding: "16px 8px",
              borderBottom: `1px solid ${theme.colors.gray100}`,
              fontSize: theme.fonts.size.default,
              fontWeight: theme.fonts.weight.regular,
              height: "50px",
            }}
          >
            {t("table.legalTenor")}
          </td>
          <td
            style={{
              textAlign: "center",
              padding: "16px 8px",
              borderBottom: `1px solid ${theme.colors.gray100}`,
              fontSize: theme.fonts.size.default,
              fontWeight: theme.fonts.weight.regular,
              backgroundColor: theme.colors.light,
              height: "50px",
            }}
          >
            {termsheetForm.tenor} años
          </td>
          <td
            style={{
              padding: "16px 8px",
              borderBottom: `1px solid ${theme.colors.gray100}`,
              height: "50px",
            }}
          >
            <Div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                id="clientTenor"
                value={termsheetForm.clientTenor}
                onChange={handleChange}
                placeholder="Años"
                style={inputStyle}
                className="placeholder-right"
              />
            </Div>
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "16px 8px",
              fontSize: theme.fonts.size.default,
              fontWeight: theme.fonts.weight.regular,
              height: "50px",
            }}
          >
            {t("table.spread")}
          </td>
          <td
            style={{
              textAlign: "center",
              padding: "16px 8px",
              fontSize: theme.fonts.size.default,
              fontWeight: theme.fonts.weight.regular,
              backgroundColor: theme.colors.light,
              height: "50px",
            }}
          >
            {termsheetForm.spread} %
          </td>
          <td style={{ padding: "16px 8px", height: "50px" }}>
            <Div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                id="clientSpread"
                value={termsheetForm.clientSpread}
                onChange={handleChange}
                placeholder="%"
                style={inputStyle}
                className="placeholder-right"
              />
            </Div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

Table.propTypes = {
  t: PropTypes.func.isRequired,
};

export const IndicativeTermSheet = ({ projectId }) => {
  const { t } = useTranslation(["indicativeTermSheet", "common"]);
  const send = useSendTermsheetComments();
  const { data, isSuccess, isLoading, refetch } = useGetTermsheet(projectId);
  const [showTable, setShowTable] = useState(false);
  const [termsheetForm, setTermsheetForm] = useState({
    projectId: projectId,
    clientTenor: null,
    clientSpread: null,
    comment: "",
    associatedTermsheetId: "",
  });

  useEffect(() => {
    if (isSuccess) {
      const withAssociatedTermsheetId = data?.data.filter(
        (item) => item.userId
      );
      const withoutAssociatedTermsheetId = data?.data.filter(
        (item) => !item.userId
      );

      const infoClient =
        withAssociatedTermsheetId[withAssociatedTermsheetId.length - 1];
      const info =
        withoutAssociatedTermsheetId[withoutAssociatedTermsheetId.length - 1];

      setTermsheetForm({
        projectId: info?.projectId,
        tenor: info?.tenor,
        spread: info?.spread,
        clientTenor: infoClient?.tenor,
        clientSpread: infoClient?.spread,
        comment: infoClient?.comment,
        associatedTermsheetId: infoClient?._id,
      });
    }
  }, [isSuccess, data]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newInfo = { ...termsheetForm };

    newInfo[id] = value;
    setTermsheetForm(newInfo);
  };

  const handleChangeNumber = (event) => {
    const { id, value } = event.target;
    const newInfo = { ...termsheetForm };

    newInfo[id] = Number(value.replace(/\D/g, ""));
    setTermsheetForm(newInfo);
  };

  const handleSend = () => {
    const payload = {
      projectId: termsheetForm.projectId,
      tenor: termsheetForm.clientTenor,
      spread: termsheetForm.clientSpread,
      comment: termsheetForm.comment,
      associatedTermsheetId: termsheetForm.associatedTermsheetId,
    };

    send.reset();
    send.mutate(payload, {
      onSuccess: () => {
        toast.success(t("savedSuccessfully"));
        refetch();
      },
      onError: (err) => {
        toast.error(t(`common:${err.response.data.result.code}`));
      },
    });
  };

  if (isLoading) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  return (
    <>
      <Div direction="column" style={{ paddingBottom: "6.25rem" }}>
        <Row justify="space-between" align="center" m="0 0 23px 0">
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h5i}
            color={theme.colors.blue}
          >
            {t("title")}
          </Text>
        </Row>
        <Card
          width="100%"
          height="auto"
          padding="23px 27px 36px 24px"
          margin="0 0 50px 0"
        >
          <Div direction="column">
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h5i}
              color={theme.colors.blue}
              mb="16px"
            >
              {t("cTitle")}
            </Text>
            <Text color={theme.colors.gray300} mb="24px">
              {t("cDescription")}
            </Text>

            <Div>
              {!showTable && (
                <Button
                  width="251px"
                  height="45px"
                  variant={"outlined"}
                  onClick={() => setShowTable(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    marginBottom: "5rem",
                  }}
                >
                  <DownloadIcon fill={theme.colors.green} />
                  {t("cMakeComments")}
                </Button>
              )}
            </Div>
          </Div>
          {showTable && (
            <Div direction="column" style={{ marginTop: "3rem" }}>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h5i}
                color={theme.colors.blue}
                mb="16px"
              >
                {t("cComment")}
              </Text>
              <Text color={theme.colors.gray300} mb="24px">
                {t("cTellUsYourComment")}
              </Text>
              <Table
                t={t}
                termsheetForm={termsheetForm}
                handleChange={handleChangeNumber}
              />
              <Text
                color={theme.colors.gray200}
                mb="8px"
                style={{
                  fontWeight: theme.fonts.weight.regular,
                }}
              >
                {t("cOtherComments")}
              </Text>
              <textarea
                id="comment"
                value={termsheetForm.comment}
                onChange={handleChange}
                style={{
                  width: "50%",
                  padding: "8px",
                  border: `1px solid ${theme.colors.gray100}`,
                  borderRadius: "7px",
                  minHeight: "100px",
                  fontSize: theme.fonts.size.default,
                  marginBottom: theme.fonts.size.default,
                }}
              ></textarea>
              <Button
                m="0 1rem 0 0"
                width="201px"
                height="45px"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "23px",
                  gap: "4px",
                  fontSize: theme.fonts.size.sm,
                }}
                onClick={handleSend}
                loading={send.isLoading}
              >
                {t("cSendComments")}
              </Button>
            </Div>
          )}
        </Card>
      </Div>
      <style>
        {`
          .placeholder-right::placeholder {
            text-align: right;
          }
        `}
      </style>
    </>
  );
};

IndicativeTermSheet.propTypes = {
  projectId: PropTypes.string,
};

Table.propTypes = {
  t: PropTypes.any,
  termsheetForm: PropTypes.object,
  handleChange: PropTypes.func,
};

export default IndicativeTermSheet;
