import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetSourceByProject = (id, projectId) => {
  const data = useQuery(["sourceInfo", id, projectId], 
    () => axiosClient.get(`financingSource/${id}/${projectId}`), { enabled: !!id }
  );

  return data;
};

export default useGetSourceByProject;