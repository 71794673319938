import React, { useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Col, Row, Text } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { dateFormat, dateFormatWithHour } from "../../../utilities/helpers";
import { Link } from "../../../components/Link";
import useAnswerQuestion from "../hooks/useAnswerQuestion";
import { TextArea } from "../../../components/TextArea";

export const ModalAnswerQuestion = ({
  showModal,
  handleCloseModal,
  selectedQuestion,
  projectId,
}) => {
  const { t } = useTranslation("promoterQuestions");

  const queryClient = useQueryClient();
  const answerQuestion = useAnswerQuestion();

  const [answerDescription, setAnswerDescription] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length < 2 || value.length > 800) {
      setError(true);
    } else {
      setError(false);
    }

    setAnswerDescription(value);
  };

  const handleCloseAndReset = () => {
    setError(false);
    setAnswerDescription("");
    handleCloseModal();
  };

  const handleResponse = () => {
    if (
      error ||
      answerDescription.length < 2 ||
      answerDescription.length > 800
    ) {
      setError(true);
    } else {
      answerQuestion.reset();
      answerQuestion.mutate(
        {
          projectId: projectId,
          questionId: selectedQuestion?.questionId,
          description: answerDescription,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getPromoterQuestions"]);
            handleCloseAndReset();
          },
          onError: (err) => {
            toast.error(
              err.response?.data?.result?.errorMessage?.message ||
                err.response?.data?.result?.code
            );
          },
        }
      );
    }
  };

  return (
    <Modal open={showModal} onCancel={handleCloseAndReset}>
      <Modal.Header
        padding={"0 32px 0px 32px"}
        title={t("Preguntas")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
        padding="0 32px"
      >
        <Col width="100%" height="100%">
          <Row width="100%" m="0 0 36px 0">
            <Text weight={theme.fonts.weight.medium} color={theme.colors.green}>
              {`${selectedQuestion?.investorName} (${selectedQuestion?.companyName})`}
            </Text>
          </Row>
          <Row width="100%" m="0 0 8px 0" justify="space-between">
            <Text
              weight={theme.fonts.weight.medium}
              color={theme.colors.gray500}
            >
              {`${t("theme")}: (${selectedQuestion?.topic})`}
            </Text>
            <Text
              color={theme.colors.gray300}
              size={theme.fonts.size.xxs}
              style={{
                backgroundColor: theme.colors.light,
                borderRadius: "13px",
                alignSelf: "center",
                padding: "4px 6px",
              }}
            >
              {dateFormat(selectedQuestion?.createdAt)}
            </Text>
          </Row>
          <Text color={theme.colors.gray500} mb="25px">
            {selectedQuestion?.question}
          </Text>
          <Row m="0 0 25px 0">
            {selectedQuestion?.status === "answered" ? (
              <Col width="100%">
                <Row justify="space-between">
                  <Text color={theme.colors.green} size={theme.fonts.size.xs}>
                    {t("ModalPromoterQuestions.response")}
                  </Text>
                  <Text
                    color={theme.colors.gray300}
                    size={theme.fonts.size.xxs}
                    style={{
                      backgroundColor: theme.colors.light,
                      borderRadius: "13px",
                      alignSelf: "center",
                      padding: "4px 6px",
                    }}
                  >
                    {dateFormatWithHour(selectedQuestion?.answeredAt)}
                  </Text>
                </Row>
                <Text color={theme.colors.gray300} size={theme.fonts.size.sm}>
                  {selectedQuestion?.answer}
                </Text>
              </Col>
            ) : (
              <TextArea
                label={t("ModalPromoterQuestions.response")}
                id="answer"
                height="93px"
                width="100%"
                rows={5}
                value={answerDescription}
                onChange={handleChange}
                error={error}
                helper={error ? t("ModalPromoterQuestions.error") : ""}
              />
            )}
          </Row>
          {selectedQuestion?.status !== "answered" && (
            <Row justify="center" m="0 0 25px 0">
              <Button
                width={"193px"}
                onClick={handleResponse}
                loading={answerQuestion.isLoading}
              >
                {t("ModalPromoterQuestions.answer")}
              </Button>
            </Row>
          )}
          <Link onClick={handleCloseAndReset}>Cerrar</Link>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalAnswerQuestion.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  selectedQuestion: PropTypes.object,
  projectId: PropTypes.string,
};
