import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPromoterQuestions = (projectId) => {
  const data = useQuery(
    ["getPromoterQuestions", projectId],
    () => axiosClient.get(`project/${projectId}/promotor-questions`),
    { enabled: !!projectId }
  );

  return data;
};
export default useGetPromoterQuestions;
