/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import filterIcon from "../../../../assets/icons/Filtrar.svg";
import search from "../../../../assets/icons/Search.svg";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Div } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { EmptyProject } from "../Promoter/emptyProject";
import { ModalUploadNewProject } from "../Promoter/modalUploadNewProject";
import { DrawerFilterManager } from "./DrawerFilterManager";
import { ProjectsTabsViewManager } from "./projectsTabsViewManager";
import { DrawerDocumentation } from "./DrawerDocumentation";
import ModalDistribution from "./ModalDistribution";
import { DrawerInvitationInvestor } from "./DrawerInvitationInvestor";
import { DrawerA5Model } from "./DrawerA5Model";
import useGetProjectStrategies from "../../hooks/useGetProjectStrategies";
import { ModalOwner } from "./ModalOwner";
import useGetDevelopmentCompanies from "../../hooks/useGetDevelopmentCompanies";
import useGetDevelopmentUsers from "../../hooks/useGetDeveloperUsers";
import { setOwnerInfo } from "../../../../core/store/projects";
import useGetProjectsManager from "../../hooks/useGetProjectsManager";
import { DrawerActivityProject } from "./DrawerActivityProject";

export const ProjectsListManager = () => {
  const { t } = useTranslation("projectsTabsView");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState("1");
  const [projectsData, setProjectsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [showModalOwner, setShowModalOwner] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [initialHasProjects, setInitialHasProjects] = useState(false);
  const [totalProjects, setTotalProjects] = useState({
    totalCreated: 0,
    totalDrafted: 0,
    totalIdentified: 0,
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchAction, setFetchAction] = useState(false);

  const [filterValues, setFilterValues] = useState({
    type: [],
    instance: [],
    status: [],
    others: [],
  });

  const [confirmedFilterValues, setConfirmedFilterValues] =
    useState(filterValues);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;
  const [payload, setPayload] = useState({
    limit: pageSize,
    offset: currentPage,
    query: searchTerm,
    selectedTab: "created",
  });

  const getDevelopmentCompanies = useGetDevelopmentCompanies();
  const getDevelopmentUsers = useGetDevelopmentUsers();
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    const payload = {
      limit: null,
      offset: null,
      companyName: "",
      companyType: "Promoter",
    };

    getDevelopmentCompanies.reset();
    getDevelopmentCompanies.mutate(payload, {
      onSuccess: (res) => {
        const companies = res?.data.data.map((ele) => ({
          label: ele.name,
          value: ele.id,
        }));
        setCompaniesOptions(companies);
      },
    });
  }, []);

  useEffect(() => {
    const payload = {
      limit: null,
      offset: null,
      userRole: "Developer",
      companyId: companyId,
    };

    if (companyId) {
      getDevelopmentUsers.reset();
      getDevelopmentUsers.mutate(payload, {
        onSuccess: (resp) => {
          const users = resp?.data.data.map((ele) => ({
            label: ele.name,
            value: ele.id,
          }));
          setUsersOptions(users);
        },
      });
    }
  }, [companyId]);

  const handleSelectCompany = (value) => {
    setCompanyId(value);
  };

  const handleSelectOwner = (value) => {
    const owner = usersOptions.find((ele) => ele.value === value)?.label;

    setOwnerId(value);
    setShowModalOwner(false);
    setShowModal2(true);

    dispatch(
      setOwnerInfo({
        owner: owner,
        ownerId: value,
      })
    );

    setCompanyId(null);
    setOwnerId(null);
  };

  const getProjects = useGetProjectsManager(payload);
  const { data: dataProjects, isLoading, isSuccess } = getProjects;
  useEffect(() => {
    if (isSuccess && dataProjects?.data) {
      setInitialLoading(true);
      const { totalCreated, totalDrafted, totalIdentified } = dataProjects.data;
      const projects =
        totalCreated !== 0 || totalDrafted !== 0 || totalIdentified !== 0;
      setProjectsData(dataProjects?.data.projects);
      if (!projectsData.length && !initialHasProjects) {
        setInitialHasProjects(projects);
      }
      setTotalProjects({
        totalCreated: totalCreated,
        totalDrafted: totalDrafted,
        totalIdentified: totalIdentified,
      });
      setInitialLoading(false);
    }
  }, [dataProjects, isSuccess]);

  useEffect(() => {
    const projectTypes = confirmedFilterValues?.type;
    const instances = confirmedFilterValues?.instance;
    const projectStatus = confirmedFilterValues?.status;
    const hasTermsheet = confirmedFilterValues?.others.includes("thermsheet");
    const hasOffers = confirmedFilterValues?.others.includes("offer");
    const isPublish = confirmedFilterValues?.others.includes("published");
    //const hasPendingQuestions = confirmedFilterValues?.others.includes("questions");

    setPayload({
      ...payload,
      limit: pageSize,
      offset: currentPage,
      query: searchTerm,
      projectType: projectTypes,
      instance: instances,
      projectStatus: projectStatus,
      hasTermsheet: hasTermsheet,
      hasOffers: hasOffers,
      isPublish: isPublish,
      //hasPendingQuestions: hasPendingQuestions, TODO pending
    });
  }, [activeKey, pageSize, currentPage, confirmedFilterValues, fetchAction]);

  const [showDocumentsDrawer, setShowDocumentsDrawer] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModalDistribution, setShowModalDistribution] = useState(false);
  const [showDrawerInvitation, setShowDrawerInvitation] = useState(false);
  const [showDrawerA5Modeling, setShowDrawerA5Modeling] = useState(false);
  const [sourceSelected, setSourceSelected] = useState("");

  const [showDrawerActivityProject, setShowDrawerActivityProject] =
    useState(false);

  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const getProjectStrategies = useGetProjectStrategies(selectedProject?._id);
  const { data: dataStrategies, isLoading: isLoadingStrategies } =
    getProjectStrategies;
  const [strategiesList, setStrategiesList] = useState([]);
  useEffect(() => {
    if (dataStrategies?.data) {
      setStrategiesList(
        dataStrategies?.data.map((item) => ({
          label: item.strategyName,
          value: item.strategyId,
        }))
      );
    }
  }, [dataStrategies]);

  const handleShowModalOwner = () => setShowModalOwner(true);
  const handleCloseModalOwner = () => {
    setShowModalOwner(false);
    setCompanyId(null);
    setOwnerId(null);
    dispatch(
      setOwnerInfo({
        owner: "",
        ownerId: "",
      })
    );
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
    setCompanyId(null);
    setOwnerId(null);
    dispatch(
      setOwnerInfo({
        owner: "",
        ownerId: "",
      })
    );
  };

  const handleCloseFilter = () => {
    setFilterValues(confirmedFilterValues);
    setShowFilter(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
  };

  useEffect(() => {
    setSourceSelected(selectedProject?.financingSource);
  }, [selectedProject]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleReFetch();
      setIsSearch(!isSearch);
    }
  };

  const handleChange = (group, id) => (e) => {
    const { checked } = e.target;
    const label = id;
    setFilterValues((prevValues) => {
      const newValues = [...prevValues[group]];

      if (checked) {
        newValues.push(label);
      } else {
        const index = newValues.indexOf(label);
        if (index > -1) {
          newValues.splice(index, 1);
        }
      }

      return {
        ...prevValues,
        [group]: newValues,
      };
    });
  };

  const handleReFetch = () => {
    setCurrentPage(0);
    setFetchAction(!fetchAction);
  };

  const resetFilterValues = () => {
    const newFilterValues = {
      type: [],
      instance: [],
      status: [],
      others: [],
    };
    setFilterValues(newFilterValues);
    setConfirmedFilterValues(newFilterValues);
    setShowFilter(false);
    handleReFetch();
  };

  const handleSaveFilters = () => {
    setConfirmedFilterValues(filterValues);
    setShowFilter(false);
    handleReFetch();
  };

  const handleCloseDrawer = () => {
    setSelectedProject(null);
    setSelectedStrategy(null);
    setShowDocumentsDrawer(false);
    setShowDrawerInvitation(false);
    setShowDrawerActivityProject(false);
  };

  const handleCloseModal = () => {
    setSelectedStrategy(null);
    setSelectedProject(null);
    setShowModalDistribution(false);
    setStrategiesList([]);
  };

  const handleOpenInvitationDrawer = () => {
    setShowModalDistribution(false);
    setShowDrawerInvitation(true);
  };

  const handleCloseA5ModelingDrawer = () => {
    setShowDrawerA5Modeling(false);
    setSelectedProject(null);
    setSourceSelected(null);
    queryClient.invalidateQueries([
      "documentsList",
      selectedProject?._id,
      2,
      8,
    ]);
  };

  const handleOpenInvitation = () => {
    navigate(
      `/projects/see-invitations?projectName=${selectedProject?.name}&status=${selectedProject?.status}&projectId=${selectedProject._id}&strategyId=${selectedStrategy?.value}`
    );
  };

  return (
    <>
      <Div style={{ flexDirection: "column", width: "100%" }}>
        <ModalOwner
          showModal={showModalOwner}
          handleCloseModal={handleCloseModalOwner}
          companiesOptions={companiesOptions}
          usersOptions={usersOptions}
          companyId={companyId}
          ownerId={ownerId}
          handleSelectCompany={handleSelectCompany}
          handleSelectOwner={handleSelectOwner}
        />
        <Div style={{ flexDirection: "column", width: "100%" }}>
          <Div
            gap="16px"
            m="0 0 39px 0"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              boxSizing: "border-box",
            }}
          >
            <Input
              sizeLabel={theme.fonts.size.xs}
              suffix={
                <img
                  alt="search"
                  src={search}
                  onClick={handleReFetch}
                  style={{ cursor: "pointer" }}
                />
              }
              width={"323px"}
              placeholder={`${t("common:search")}...`}
              style={{ borderRadius: "23px", marginTop: "-10px" }}
              mt={"-8px"}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            {initialHasProjects > 0 && (
              <>
                <Button width={"153px"} onClick={handleShowModalOwner}>
                  {t("newProject")}
                </Button>
              </>
            )}
            <Button
              width={"110px"}
              variant={"outlined"}
              icon
              onClick={() => setShowFilter(true)}
            >
              {t("filter")}
              <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
            </Button>
            <DrawerFilterManager
              openDrawer={showFilter}
              handleClose={handleCloseFilter}
              filterValues={filterValues}
              handleChange={handleChange}
              handleCleanFilters={resetFilterValues}
              handleSaveFilters={handleSaveFilters}
            />
            <DrawerA5Model
              openDrawer={showDrawerA5Modeling}
              handleClose={handleCloseA5ModelingDrawer}
              selectedProject={selectedProject}
              sourceSelected={sourceSelected}
              setSourceSelected={setSourceSelected}
              payload={payload}
            />
            <DrawerDocumentation
              openDrawer={showDocumentsDrawer}
              handleClose={handleCloseDrawer}
              selectedProject={selectedProject}
            />
            <ModalDistribution
              showModal={showModalDistribution}
              handleCloseModal={handleCloseModal}
              selectedStrategy={selectedStrategy}
              setSelectedStrategy={setSelectedStrategy}
              handleOpenInvitationDrawer={handleOpenInvitationDrawer}
              data={strategiesList}
              isLoadingStrategies={isLoadingStrategies}
              handleOpenInvitation={handleOpenInvitation}
              projectId={selectedProject?._id}
            />
            <DrawerInvitationInvestor
              openDrawer={showDrawerInvitation}
              handleClose={handleCloseDrawer}
              selectedProject={selectedProject}
              selectedStrategy={selectedStrategy}
              setSelectedStrategy={setSelectedStrategy}
              data={strategiesList}
            />
            <DrawerActivityProject
              openDrawer={showDrawerActivityProject}
              handleClose={handleCloseDrawer}
              selectedProject={selectedProject}
            />
          </Div>
        </Div>

        {initialLoading ? (
          <Div width="100%" height="100%" justify="center" align="center">
            <ReactLoading color={theme.colors.green} />
          </Div>
        ) : !initialHasProjects ? (
          <EmptyProject />
        ) : (
          <>
            <ProjectsTabsViewManager
              projects={projectsData}
              totalProjects={totalProjects}
              payload={payload}
              setPayload={setPayload}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setShowDocumentsDrawer={setShowDocumentsDrawer}
              setShowModalDistribution={setShowModalDistribution}
              setSelectedProject={setSelectedProject}
              setShowDrawerA5Modeling={setShowDrawerA5Modeling}
              companiesOptions={companiesOptions}
              usersOptions={usersOptions}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              isLoading={isLoading}
              handleReFetch={handleReFetch}
              setSearchTerm={setSearchTerm}
              setShowDrawerActivityProject={setShowDrawerActivityProject}
            />
          </>
        )}
        {showModal2 && (
          <ModalUploadNewProject
            showModal={showModal2}
            handleCloseModal={handleCloseModal2}
          />
        )}
      </Div>
    </>
  );
};
