import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Div, Text, Row, Col } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import Drawer from "../../../../components/Drawer";
import { Button } from "../../../../components/Button";
import { Link } from "../../../../components/Link";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { SearchIcon } from "../../../../assets/icons";
import Checkbox from "../../../../components/Checkbox";
import { Input } from "../../../../components/Input";
import useGetCompaniesList from "../../../InviteInvestor/hooks/useGetCompaniesList";
import useSendInvestorsNotification from "../../hooks/useSendInvestorsNotification";
import { Select } from "../../../../components/Select2";
import useGetCompanyFindByStrategy from "../../hooks/useGetCompanyFindByStrategy";

export const DrawerInvitationInvestor = ({
  openDrawer,
  handleClose,
  selectedProject,
  selectedStrategy,
  setSelectedStrategy,
  data,
}) => {
  const { t } = useTranslation("projects");
  const sendInvitation = useSendInvestorsNotification();

  const [searchValue, setSearchValue] = useState("");

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const getCompanyFindByStrategy = useGetCompanyFindByStrategy(
    selectedStrategy?.value,
    selectedProject?._id
  );
  const {
    data: dataCompanyFindByStrategy,
    isSuccess: isSuccessCompanyFindByStrategy,
  } = getCompanyFindByStrategy;
  const [companiesList, setCompaniesList] = useState([]);
  useEffect(() => {
    if (isSuccessCompanyFindByStrategy) {
      setCompaniesList(
        dataCompanyFindByStrategy?.data?.data.map((company) => ({
          name: company?.name
            ? company.name.charAt(0).toUpperCase() + company.name.slice(1)
            : "-",
          id: company._id,
        }))
      );
    }
  }, [dataCompanyFindByStrategy, isSuccessCompanyFindByStrategy]);

  const getCompaniesList = useGetCompaniesList(selectedStrategy?.value);
  const { data: dataCompany, isSuccess: isSuccessCompany } = getCompaniesList;
  const [otherCompanies, setOtherCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  useEffect(() => {
    if (isSuccessCompany) {
      setOtherCompanies(
        dataCompany?.data.map((company) => ({
          name: company?.name
            ? company.name.charAt(0).toUpperCase() + company.name.slice(1)
            : "-",
          id: company._id,
        }))
      );
      setFilteredCompanies(
        dataCompany?.data.map((company) => ({
          name: company?.name
            ? company.name.charAt(0).toUpperCase() + company.name.slice(1)
            : "-",
          id: company._id,
        }))
      );
    }
  }, [dataCompany, isSuccessCompany]);

  const handleCheckboxChange = (company) => {
    setSelectedCompanies((prevCompanies) => {
      const companyIndex = prevCompanies.findIndex(
        (comp) => comp.id === company.id
      );
      if (companyIndex !== -1) {
        return prevCompanies.filter((comp) => comp.id !== company.id);
      } else {
        return [...prevCompanies, company];
      }
    });
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    const filtered = otherCompanies.filter((company) =>
      company.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  const handleCloseAndReset = () => {
    setSelectedCompanies([]);
    setSearchValue("");
    handleClose();
  };

  const handleSendInvitation = () => {
    if (selectedCompanies.length > 0) {
      sendInvitation.reset();
      sendInvitation.mutate(
        {
          projectId: selectedProject?._id,
          investorsIds: selectedCompanies
            .map((company) => company.id)
            .filter((id) => id),
          strategyId: selectedStrategy?.value,
        },
        {
          onSuccess: () => {
            toast.success(t("common:invitationsSuccessfully"));
            setSelectedCompanies([]);
            handleCloseAndReset();
          },
          onError: (err) => {
            toast.error(
              t(
                `common:${
                  err.response.data.result.code.message ||
                  err.response.data.result.code
                }`
              )
            );
          },
        }
      );
    } else {
      toast.error(t("common:error"));
    }
  };

  return (
    <Drawer
      title={selectedProject?.name}
      open={openDrawer}
      onClose={handleCloseAndReset}
      width="348px"
      padding="10px 36px"
    >
      <Text
        color={theme.colors.blue}
        size={theme.fonts.size.h5i}
        mb="16px"
        mt="8px"
        weight={theme.fonts.weight.medium}
      >
        {t("drawerInvitationInvestor.invite")}
      </Text>
      <Div
        style={{
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
        direction="column"
      >
        <Text color={theme.colors.gray500} mb="11px">
          {t("drawerInvitationInvestor.list")}
        </Text>

        <Row align="center" width="100%" gap="5px" m=" 0 0 33px 0">
          <Select
            colorLabel={theme.colors.gray200}
            label={t("drawerInvitationInvestor.strategy")}
            sizeLabel={theme.fonts.size.default}
            options={data}
            width="100%"
            value={selectedStrategy?.value}
            arrowColor={theme.colors.gray200}
            onChange={(value, option) => setSelectedStrategy(option)}
          />
        </Row>

        <Row>
          <Text
            color={theme.colors.blue}
            size={theme.fonts.size.h6}
            mb="24px"
            mt="8px"
            weight={theme.fonts.weight.medium}
          >
            {t("drawerInvitationInvestor.companies")}
          </Text>
        </Row>
        {dataCompanyFindByStrategy?.data?.data.length === 0 && (
          <Row>
            <Text>No hay compañías</Text>
          </Row>
        )}
        <Col m=" 0 0 30px 0 ">
          {companiesList.map((company, index) => (
            <Div
              key={index}
              height="54px"
              p="0 20px 0 20px"
              background={index % 2 === 0 && theme.colors.green100}
              direction="column"
              justify="center"
              radius="10px"
            >
              <Row gap="5px">
                <Checkbox
                  value={company.id}
                  checked={selectedCompanies.some(
                    (comp) => comp.id === company.id
                  )}
                  onChange={() => handleCheckboxChange(company)}
                />
                <Text
                  color={theme.colors.gray300}
                  minheight="23px"
                  align="left"
                >
                  {company.name}
                </Text>
              </Row>
            </Div>
          ))}
        </Col>

        <Col m=" 0 0 20px 0">
          <Text
            color={theme.colors.blue}
            size={theme.fonts.size.h6}
            mb="24px"
            mt="8px"
            weight={theme.fonts.weight.medium}
          >
            {`${t("drawerInvitationInvestor.other")}`}
          </Text>
          <Text color={theme.colors.gray500} mb="11px">
            {t("drawerInvitationInvestor.otherInvestors")}
          </Text>
          <Row>
            <Input
              placeholder={t("drawerInvitationInvestor.search")}
              suffix={<SearchIcon />}
              width="100%"
              height="54px"
              background={theme.colors.white}
              border={theme.colors.gray100}
              radius="40px"
              colorPlaceholder={theme.colors.gray300}
              value={searchValue}
              onChange={handleChange}
            />
          </Row>
        </Col>
        <Col m=" 0 0 30px 0 ">
          {filteredCompanies.map((company, index) => (
            <Div
              key={index}
              height="54px"
              p="0 20px 0 20px"
              justify="center"
              background={index % 2 === 0 && theme.colors.green100}
              direction="column"
              radius="10px"
            >
              <Row gap="5px">
                <Checkbox
                  value={company.id}
                  checked={selectedCompanies.some(
                    (comp) => comp.id === company.id
                  )}
                  onChange={() => handleCheckboxChange(company)}
                />
                <Text
                  color={theme.colors.gray300}
                  minheight="23px"
                  align="left"
                >
                  {company.name}
                </Text>
              </Row>
            </Div>
          ))}
        </Col>
      </Div>
      <Col>
        <Button
          width="285px"
          height="50px"
          m="0 0 15px 0"
          onClick={handleSendInvitation}
          loading={sendInvitation.isLoading}
        >
          {t("drawerInvitationInvestor.send")}
        </Button>
        <Button variant={"outlined"} width="285px" height="50px" m="0 0 20px 0">
          {t("drawerInvitationInvestor.unregistered")}
        </Button>
        <Div
          direction="column"
          align="center"
          justify="center"
          gap="24px"
          style={{ marginTop: "auto" }}
          m=" 0 0 20px 0"
        >
          <Link onClick={handleCloseAndReset}>
            {t("drawerInvitationInvestor.cancel")}
          </Link>
        </Div>
      </Col>
    </Drawer>
  );
};

DrawerInvitationInvestor.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
  selectedStrategy: PropTypes.object,
  setSelectedStrategy: PropTypes.func,
  data: PropTypes.array,
};
